import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/service/auth.service';
import { User } from './model';

@Injectable({
  providedIn: 'root'
})
export class ActiveRouteResolver implements Resolve<boolean> {

  constructor(private authService: AuthenticationService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.userInfo().pipe(
      take(1),
      mergeMap((data: User) => {
        //console.log(data);
        let roles = route.data.roles as Array<string>;
        if((data  && roles.some(element => data.authorities!.includes(element)))){
          return of(true);
        }else{
          this.router.navigate(['/']);
          return EMPTY;
        }
      })
    );

  }
}
