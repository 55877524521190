import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { User } from '../models/auth.models';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    user: User | null = null;
    token: string | null = null;
    apiUrl: string = environment.apiUrl + '/api';

    constructor (private http: HttpClient, private router: Router) {
    }

    /**
     * Returns the current user
     */
     public currentUser(): User | null {
        if (!this.user) {
            this.user = JSON.parse(sessionStorage.getItem('currentUser')!);
        }
        return this.user;
    }

    userInfo(): any {
        return this.http.get<any>(`${this.apiUrl}/account`)
            .pipe(map
                (user => {
                // login successful if there's a jwt token in the response
                if (user && user.login) {
                    this.user = user;
                    //this.user!.imageUrl = "assets/images/users/avatar-5.jpg";
                    this.user!.imageUrl = "assets/site/user.png";
                    // store user details and jwt in session
                    sessionStorage.setItem('currentUser', JSON.stringify(this.user));
                }
                return user;
            }));
        
    }


    /**
     * Returns the current token
     */
    public currentToken(): string | null {
        if (!this.token) {
            this.token = JSON.parse(sessionStorage.getItem('currentToken')!);
        }
        return this.token;
    }

    /**
     * Performs the login auth
     * @param username username of user
     * @param password password of user
     */
    login(username: string, password: string): any {

        return this.http.post<any>(`${this.apiUrl}/authenticate`, { username, password })
            .pipe(map
                (token => {
                // login successful if there's a jwt token in the response
                if (token && token.id_token) {
                    this.token = token.id_token;
                    // store user details and jwt in session
                    sessionStorage.setItem('currentToken', JSON.stringify(this.token));
                }
                return token;
            }));
    }
    register(user: User): any {

        return this.http.post<any>(`${this.apiUrl}/register`, user);
    }

    requestPasswordReset(email: string): any {
        return this.http.post<any>(`${this.apiUrl}/account/reset-password/init`, email);
    }

    finishPasswordReset(key: string, newPassword: string): any {
        return this.http.post<any>(`${this.apiUrl}/account/reset-password/finish`, { key, newPassword });
    }

    activateAccount(key: string): any {
        return this.http.get<any>(`${this.apiUrl}/activate?key=${key}`);
    }
    /**
     * Performs the signup auth
     * @param name name of user
     * @param email email of user
     * @param password password of user
     */
    signup(name: string, email: string, password: string): any {
        return this.http.post<any>(`/api/register`, { name, email, password })
            .pipe(map(user => user));

    }



    /**
     * Logout the user
     */
    logout(): void {
        // remove user from session storage to log user out
        if(sessionStorage.getItem('currentToken')){
            sessionStorage.removeItem('currentToken');
        }
        if(sessionStorage.getItem('currentUser')){
            sessionStorage.removeItem('currentUser');
        }
        
        this.token = null;
        //this.router.navigate(['/']);
    }
}

