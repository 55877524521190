import { MenuItem } from '../models/menu.model';

export const MENU: MenuItem[] = [
    
    { key: 'navigation', label: 'Navigation', isTitle: true },
    /* { key: 'feature-tableau-de-bord', label: 'Tableau de bord', isTitle: false, link: '/admin/feature/accueil', icon: 'uil-home-alt'}, */
    /* { key: 'feature-tableau-de-bord', label: 'Tableau de bord', isTitle: false, link: '/admin/feature/accueil', icon: 'uil-home-alt'},
     */
    {
        key: 'feature-boutique', label: 'Boutique', isTitle: false, icon: 'uil-store', collapsed: true,
        children: [
            { key: 'feature-produit', label: 'Produits', isTitle: false, link: '/admin/feature/produit', parentKey: 'feature-boutique'},
            /* { key: 'feature-commande', label: 'Commandes', isTitle: false, link: '/admin/feature/commande', parentKey: 'feature-boutique'}, */
            { key: 'feature-client', label: 'Clients', isTitle: false, link: '/admin/feature/client', parentKey: 'feature-boutique'},
            { key: 'feature-restokage', label: 'Restockage', isTitle: false, link: '/admin/feature/restokage', parentKey: 'feature-boutique'},
            { key: 'feature-categorie', label: 'Catégories', isTitle: false, link: '/admin/feature/categorie', parentKey: 'feature-boutique'},
            { key: 'feature-sous-categorie', label: 'Sous Catégories', isTitle: false, link: '/admin/feature/sous-categorie', parentKey: 'feature-boutique'},
            
        ]
    },
    

    
    //Blog
    {
        key: 'feature-blog', label: 'Blog', isTitle: false, icon: 'uil-clipboard-alt', collapsed: true,
        children: [
            { key: 'feature-article', label: 'Articles', link: '/admin/feature/blog/article', parentKey: 'feature-blog' },
            { key: 'feature-actuce', label: 'Actuces', link: '/admin/feature/blog/actuce', parentKey: 'feature-blog' },
            /* { key: 'feature-commentaire', label: 'Commentaires', link: '/admin/feature/blog/commentaire', parentKey: 'feature-blog' }, */
            { key: 'feature-tag', label: 'Tag', link: '/admin/feature/blog/tag', parentKey: 'feature-blog' },
        ]
    },

    { key: 'feature-consultation', label: 'Consultation', isTitle: false, link: '/admin/feature/consultation', icon: 'uil-chat-bubble-user'},

    { key: 'parametres', label: 'Paramètres', isTitle: true },
    { key: 'feature-utilisateur', label: 'Utilisateurs', isTitle: false, link: '/admin/feature/utilisateur', icon: 'uil-users-alt'},
    /* {
        key: 'apps-email', label: 'Email', isTitle: false, icon: 'uil-envelope', collapsed: true,
        children: [
            { key: 'email-inbox', label: 'Inbox', link: '/admin/apps/email/inbox', parentKey: 'apps-email' },
            { key: 'email-read-email', label: 'Read Email', link: '/admin/apps/email/read', parentKey: 'apps-email' },
        ]
    },
    {
        key: 'forms', label: 'Forms', isTitle: false, icon: 'uil-document-layout-center', collapsed: true,
        children: [
            { key: 'form-basic', label: 'Basic Elements', link: '/admin/forms/basic', parentKey: 'forms' },
            { key: 'form-advanced', label: 'Form Advanced', link: '/admin/forms/advanced', parentKey: 'forms' },
            { key: 'form-validation', label: 'Validation', link: '/admin/forms/validation', parentKey: 'forms' },
            { key: 'form-wizard', label: 'Wizard', link: '/admin/forms/wizard', parentKey: 'forms' },
            { key: 'form-upload', label: 'File Upload', link: '/admin/forms/upload', parentKey: 'forms' },
            { key: 'form-editors', label: 'Editors', link: '/admin/forms/editors', parentKey: 'forms' },
        ]
    }, */
    

]