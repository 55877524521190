import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { ActiveRouteResolver } from './feature/shared/active-route.resolver';
import { Habilitation } from './feature/shared/constant.enum';
import { LayoutContainerComponent } from './layouts/layout-container.component';
import { Error404Component } from './pages/other-pages/custom-pages/error/error404/error404.component';
import { Error500Component } from './pages/other-pages/custom-pages/error/error500/error500.component';
import { MaintenanceComponent } from './pages/other-pages/custom-pages/others/maintenance/maintenance.component';

const routes: Routes = [
  /* {
    path: "",
    redirectTo: "website",
    pathMatch: "full"
  }, */
  { path: '', loadChildren: () => import('./espace-client/espace-client.module').then(m => m.EspaceClientModule) },
  { path: 'website', loadChildren: () => import('./web-site/web-site.module').then(m => m.WebSiteModule) },

  {
    path: "admin",
    component:LayoutContainerComponent,
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
    canActivate: [AuthGuard]
  },
  {
    path: "admin/feature",
    component:LayoutContainerComponent,
    data: {roles: [Habilitation.ROLE_ADMIN ]},
    resolve: {activeRoute: ActiveRouteResolver},
    loadChildren: () => import('./feature/feature.module').then(m => m.FeatureModule),
    canActivate: [AuthGuard]
  },

  { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  { path: 'landing', loadChildren: () => import('./pages/other-pages/custom-pages/landing/landing.module').then(m => m.LandingModule) },
  { path: 'error-404', component: Error404Component },
  { path: 'error-500', component: Error500Component },
  { path: 'maintenance', component: MaintenanceComponent },
  /* { path: 'landing', loadChildren: () => import('./pages/other-pages/custom-pages/landing/landing.module').then(m => m.LandingModule) }, */
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
